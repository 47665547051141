import React, { useLayoutEffect } from 'react'
import { navigate } from 'gatsby'
import { CookiesProvider } from 'react-cookie'
import { getAllPages } from '../lib/umbraco-heartcore'
import Layout from '../components/layout'
import Seo from '../components/seo'
import NestedContent from '../components/nested-content'
import Footer from '../components/footer'
import Header from '../components/header'
import Alert from '../components/utilities/Alert'

const PreviewPages = ({ serverData }) => {
  useLayoutEffect(() => {
    if (process.env.PREVIEWMODEENABLED === 'false') {
      navigate('/404.html/')
    }
  }, [])
  const heroNestedContentItems = serverData.data.allPage.edges[0].node.heroStyle
  const nestedContentItems = serverData.data.allPage.edges[0].node.contentElements
  const header = serverData.data.headerSettings.edges[0].node
  const footer = serverData.data.footerSettings.edges[0].node
  const alert = serverData.data.alertSettings.edges[0].node
  return (
    <>
      <CookiesProvider>
        <div>
          <div id="wrapper">
            <Header previewData={header} />
            <Layout>
              <Seo
                title={serverData.data.allPage.edges[0].node.metaTitle}
                name={serverData.data.allPage.edges[0].node.name}
              />
              <NestedContent data={heroNestedContentItems} />
              <NestedContent data={nestedContentItems} />
            </Layout>
            <Footer previewData={footer} />
          </div>
        </div>
        <Alert previewData={alert} />
      </CookiesProvider>
    </>
  )
}

export default PreviewPages

export async function getServerData({ query, preview = process.env.PREVIEWMODEENABLED }) {
  const { data } = (await getAllPages(query.id, preview)) || []
  return {
    props: {
      data: data,
      preview
    }
  }
}
